
  import {prepareImageUrl} from '@/helper/ImageHelper';

  export default {
    name: 'SectionCityCarousel',
    components: {
      CustomSlider: () => import('@/components/CustomSlider'),
    },
    props: {
      section: {
        type: Object,
        required: false,
        default: () => {},
      },
      loggedIn: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    serverCacheKey: function (props) {
      return (
        'section-city-carousel-' +
        props.section.id +
        '-' +
        (props.section.route_name
          ? props.section.route_name
          : 'landingpages-cities-city')
      );
    },
    data() {
      return {
        sliderElements: [],
      };
    },
    computed: {
      numberOfElements() {
        if (
          this.$vuetify.breakpoint.width <=
          this.$vuetify.breakpoint.thresholds.xs
        ) {
          return 'two_2';
        } else if (
          this.$vuetify.breakpoint.width <=
          this.$vuetify.breakpoint.thresholds.sm
        ) {
          return 'three_3';
        } else {
          return this.section.number_of_elements ?? 'three_3';
        }
      },
    },
    created() {
      // prepare carousel items
      if (this.section.cities) {
        this.section.cities.forEach((city) => {
          if (city.card_image !== null) {
            // differ target link for routing
            const params = {};
            const route_name = this.section.route_name
              ? this.section.route_name
              : 'landingpages-cities-city';
            params.city = city.slug;

            if (this.section.activity) {
              params.activity = this.section.activity.slug;
            }

            const route = {
              name: route_name,
              params: params,
            };

            let link_title = '';
            if (this.section.link_title) {
              link_title = this.section.link_title.replace(
                /%city_name/g,
                city.name
              );
            }

            this.sliderElements.push({
              text: city.title,
              image: {
                url: prepareImageUrl(city.card_image.url),
                alt: city.card_image.alternativeText,
              },
              link: this.localePath(route),
              link_title: link_title,
            });
          }
        });
      }
    },
  };
